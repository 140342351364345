import { flowRight, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import {
  MODAL_TYPE_SHARE_LINK,
  SOCIAL_SHARING_LINK,
  SHARE_BUTTON_TRANSLATION_KEYS,
  openShareDialog,
  getOuterUrl,
  getCustomPostUrl,
  SOCIAL_SHARING_PRINT,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_POST_PRINT } from '@wix/communities-blog-experiments';

import { connect } from '../runtime-context';
import { BUTTON_SHARE_POST } from '../../bi-events/bi-buttons';
import PostShareButton from '../../../post-page/components/post-share-button';
import withTranslate from '../../hoc/with-translate';
import withSectionUrl from '../../hoc/with-section-url';
import { isSite } from '../../store/basic-params/basic-params-selectors';
import { getIsSocialSharingProviderEnabled } from '../../selectors/app-settings-selectors';
import printService from '../../services/print-service';

import styles from './post-social-actions.scss';

const containerId = (postId) => `post-social-actions_${postId}`;

export class PostSocialActions extends Component {
  url = () => this.props.customPostUrl || getOuterUrl(`/${this.props.postSlug}`, this.props.sectionUrl);

  handleShare = (provider) => {
    const { buttonClicked, postId, isSite, openModal, postPageSocialShareClicked } = this.props;
    if (!isSite) {
      return;
    }

    postPageSocialShareClicked({ postId, type: provider });
    postId && buttonClicked({ action: BUTTON_SHARE_POST, postId, shareType: provider });
    if (provider === SOCIAL_SHARING_LINK) {
      openModal(MODAL_TYPE_SHARE_LINK, { url: this.url() });
    } else {
      openShareDialog(provider, this.url());
    }
  };

  render() {
    const {
      t,
      isSite,
      enabledProviders,
      postId,
      isPostPrintExperimentEnabled,
      postPrintEnabled,
      postPageSocialShareClicked,
    } = this.props;
    const tooltipText = isSite ? '' : t('post-social-actions.preview-mode-tooltip');

    return (
      <div className={classNames(styles.container, 'blog-text-color')} tabIndex="-1" id={containerId(postId)}>
        {map(enabledProviders, (provider) => (
          <span key={provider} className={styles.button}>
            <PostShareButton
              type={provider}
              title={t(SHARE_BUTTON_TRANSLATION_KEYS[provider])}
              onClick={this.handleShare}
              tooltipText={tooltipText}
            />
          </span>
        ))}
        {isPostPrintExperimentEnabled && postPrintEnabled && (
          <span className={styles.button}>
            <PostShareButton
              type={SOCIAL_SHARING_PRINT}
              title={t(SHARE_BUTTON_TRANSLATION_KEYS[SOCIAL_SHARING_PRINT])}
              onClick={() => {
                postPageSocialShareClicked({ postId, type: SOCIAL_SHARING_PRINT });
                printService.print();
              }}
              tooltipText={tooltipText}
            />
          </span>
        )}
      </div>
    );
  }
}

PostSocialActions.propTypes = {
  postId: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  postPageSocialShareClicked: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isSite: PropTypes.bool,
  sectionUrl: PropTypes.string,
  enabledProviders: PropTypes.array.isRequired,
  customPostUrl: PropTypes.array.isRequired,
  postSlug: PropTypes.string.isRequired,
  isPostPrintExperimentEnabled: PropTypes.bool.isRequired,
  postPrintEnabled: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  isSite: isSite(state),
  openModal: actions.openModal,
  buttonClicked: actions.buttonClicked,
  postPageSocialShareClicked: actions.postPageSocialShareClicked,
  customPostUrl: getCustomPostUrl(state, ownProps.postSlug),
  isPostPrintExperimentEnabled: isExperimentEnabled(state, EXPERIMENT_POST_PRINT),
  postPrintEnabled: getIsSocialSharingProviderEnabled(state, SOCIAL_SHARING_PRINT),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate, withSectionUrl)(PostSocialActions);
