import React from 'react';

export const PrintIcon = (props) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" role="img" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 16C4.44772 16 4 15.5523 4 15V14H2C0.89543 14 0 13.1046 0 12V4C0 2.89543 0.89543 2 2 2H4C4 0.89543 4.89543 0 6 0L12 0C13.1046 0 14 0.89543 14 2H16C17.1046 2 18 2.89543 18 4V12C18 13.1046 17.1046 14 16 14H14V15C14 15.5523 13.5523 16 13 16H5ZM5 10V15H13V10H5ZM14 13H16C16.5523 13 17 12.5523 17 12V4C17 3.44772 16.5523 3 16 3H2C1.44772 3 1 3.44772 1 4V12C1 12.5523 1.44772 13 2 13H4V10H3V9H15V10H14V13ZM13 2C13 1.44772 12.5523 1 12 1H6C5.44772 1 5 1.44772 5 2H13ZM11 5H14V6H11V5Z"
      />
    </svg>
  );
};
